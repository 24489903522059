var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"ls-category"},[_c('div',{staticClass:"ls-category__top ls-card"},[_c('el-alert',{attrs:{"title":"温馨提示：1.点击分类名称前符号，显示该商品分类的下级分类。","type":"info","show-icon":"","closable":false}})],1),_c('div',{staticClass:"ls-category__content m-t-16 ls-card"},[_c('el-form',{attrs:{"inline":""}},[_c('el-form-item',{attrs:{"label":"平台"}},[_c('el-select',{attrs:{"size":"small"},on:{"change":_vm.getList},model:{value:(_vm.form.type),callback:function ($$v) {_vm.$set(_vm.form, "type", $$v)},expression:"form.type"}},[_c('el-option',{attrs:{"label":"用户端","value":1}}),_c('el-option',{attrs:{"label":"商户端","value":2}})],1)],1),_c('el-form-item',[_c('el-button',{attrs:{"size":"small","type":"primary"},on:{"click":function($event){return _vm.$router.push('/instructions/menus_edit')}}},[_vm._v(" 新增菜单 ")])],1)],1),_c('div',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.pager.loading),expression:"pager.loading"}],staticClass:"ls-content__table m-t-16"},[_c('u-table',{ref:"plTreeTable",attrs:{"data":_vm.pager.lists,"fixed-columns-roll":"","height":800,"treeConfig":{
                    children: 'children',
                    expandAll: false
                },"use-virtual":"","row-id":"id","border":false,"size":"mini"}},[_c('u-table-column',{attrs:{"tree-node":true,"prop":"label","label":"分类名称"},scopedSlots:_vm._u([{key:"default",fn:function({ row }){return [_c('span',[_vm._v(_vm._s(_vm.processInput(row.label)))])]}}])}),_c('u-table-column',{attrs:{"label":"是否显示"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('el-switch',{attrs:{"active-value":1,"inactive-value":0},on:{"change":function($event){return _vm.handleStatus($event, scope.row.id)}},model:{value:(scope.row.status),callback:function ($$v) {_vm.$set(scope.row, "status", $$v)},expression:"scope.row.status"}})]}}])}),_c('u-table-column',{attrs:{"prop":"sort","label":"排序"}}),_c('u-table-column',{attrs:{"label":"操作"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('div',{staticClass:"flex"},[_c('div',[_c('el-button',{attrs:{"type":"text","size":"small"},on:{"click":function($event){return _vm.$router.push({
                                            path: '/instructions/menus_edit',
                                            query: { id: scope.row.id }
                                        })}}},[_vm._v("编辑")]),_c('el-divider',{attrs:{"direction":"vertical"}})],1),_c('ls-dialog',{attrs:{"content":`确定删除：${_vm.processInput(scope.row.label)}？请谨慎操作。`},on:{"confirm":function($event){return _vm.handleDelete(scope.row.id)}}},[_c('el-button',{staticStyle:{"color":"red"},attrs:{"slot":"trigger","type":"text","size":"small"},slot:"trigger"},[_vm._v("删除")])],1)],1)]}}])})],1)],1)],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }

import { RequestPaging } from '@/utils/util'
import { Component, Vue } from 'vue-property-decorator'
import LsDialog from '@/components/ls-dialog.vue'
import LsPagination from '@/components/ls-pagination.vue'
import { apiHelpMenuDeleteMenu, apiHelpMenuGetMenu, apiHelpMenuUpdateMenu } from '@/api/instructions'
import { processInput } from "@/utils/util"
@Component({
    components: {
        LsDialog,
        LsPagination
    }
})
export default class Category extends Vue {
    $refs!: { plTreeTable: any }
    pager = new RequestPaging()
    form = {
        type: 2
    }
    processInput = processInput

    getList(): void {
        this.pager
            .request({
                callback: apiHelpMenuGetMenu,
                params: {
                    ...this.form
                }
            })
            .then(res => {})
    }
    handleStatus(value: number, id: number) {
        apiHelpMenuUpdateMenu({
            id,
            status: value
        }).then(() => {
            this.getList()
        })
    }

    handleDelete(id: number) {
        apiHelpMenuDeleteMenu({ id }).then(() => {
            this.getList()
        })
    }

    created() {
        this.getList()
    }
    activated() {
        this.getList()
    }
}
